"use strict";

document.addEventListener("DOMContentLoaded", function () {
    var params = new URLSearchParams(window.location.search);

    var currentFontFamily = window.getComputedStyle(document.body).fontFamily;

    if (params.has("newFont")) {
        addNewFont(currentFontFamily);
    }

    if (params.has("oldFont")) {
        removeNewFont(currentFontFamily);
    }
});

var newFontName = "Jeko";

function toggleNewFont() {
    var currentFontFamily = window.getComputedStyle(document.body).fontFamily;
    var newFont = newFontName;
    if (currentFontFamily.includes(newFontName)) {
        // remove new font if found
        currentFontFamily = currentFontFamily.replace(newFontName + ", ", "");
    } else {
        // add the new font
        currentFontFamily = newFont + ", " + currentFontFamily;
    }
    document.body.style.fontFamily = currentFontFamily;
}
function addNewFont(currentFontFamily) {
    document.body.style.fontFamily = newFontName + ", " + currentFontFamily;
}

function removeNewFont(currentFontFamily) {
    currentFontFamily = currentFontFamily.replace("\"" + newFontName + "\", ", "");
    document.body.style.fontFamily = currentFontFamily;
}